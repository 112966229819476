body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.App {
  text-align: center;
  background-color: #282c34;
  color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dropzone-container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropzone {
  width: 50%;
  max-width: 600px;
  height: 50%;
  max-height: 400px;
  border: 2px dashed #61dafb;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  color: #61dafb;
  font-size: 1.5rem;
}

.dropzone.active {
  background-color: rgba(97, 218, 251, 0.1);
}

.uploaded-image {
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
}

.exif-data {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 600px;
  text-align: left;
  color: #61dafb;
  margin-top: 20px;
}

.exif-data h2 {
  margin-top: 0;
  font-size: 1.5rem;
}

.exif-data pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}
